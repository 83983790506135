import React from 'react';
import { breakpoints } from '@web-for-marketing/react-ui';
import { Container } from '@web-for-marketing/react-ui';

const containerStyle = {
    paddingTop: '8rem',
    paddingBottom: '8rem',
    [`@media (max-width: ${breakpoints.md})`]: {
        paddingTop: '4rem',
        paddingBottom: '4rem',
    },
};

export function SectionContainer({ children, ...otherProps }: React.ComponentProps<typeof Container>): JSX.Element {
    return (
        <Container css={containerStyle} {...otherProps}>
            {children}
        </Container>
    );
}
