import { FooterNavigation, FooterSocialMediaLink } from '@models/footer';
import {
    MenuNavigation,
    NavbarDropDownColumn,
    NavbarDropDownColumnType,
    NavbarLeftMainContentItem,
    NavbarLeftMainContentType,
    NavbarLink,
    NavbarRightMainContentItem,
    NavbarRightMainContentType,
} from '@models/navigationBar';
import { AccordianContentType } from '@models/accordian';
import {
    dropdownAbout,
    dropdownProducts,
    dropdownResources,
    dropdownSolutions,
    dropdownSupport,
} from '@helpers/staticImages/navigation';

export const microFormContentTypes = {
    link: {
        type: 'link',
        content: {
            title: 'link text',
            href: '',
            isTargetToNewTab: false,
        },
    },
};

export const mainContent: AccordianContentType[] = [
    { type: 'button', name: 'Add button' },
    { type: 'popout-button', name: ' Add popout button' },
    { type: 'dropdown', name: 'Add dropdown' },
    { type: 'link', name: 'Add link' },
];

export const microContent: AccordianContentType[] = [{ type: 'link', name: 'Add link' }];

export const topFooterContent = [
    { type: 'imageLink', name: 'Add Image Link' },
    { type: 'sectionLink', name: 'Add Link Group' },
];

export const bottomFooterContent = [{ type: 'link', name: 'Add Link' }];

export const footerSocialMediaLink = [{ type: 'socialMediaLink', name: 'Add social media link' }];

export const defaultFooterSocialMediaLinks: FooterSocialMediaLink[] = [
    {
        title: 'Go to Geotab\'s facebook page',
        imagePath: '{{mediaUrlPrefix}}/Footer/Social-Media-Icons/facebook-icon.svg',
        href: 'https://www.facebook.com/Geotab',
        type: 'socialMediaLink',
    },
    {
        title: 'Go to Geotab\'s twitter page',
        imagePath: '{{mediaUrlPrefix}}/Footer/Social-Media-Icons/twitter-icon.svg',
        href: 'https://twitter.com/geotab',
        type: 'socialMediaLink',
    },
    {
        title: 'Go to Geotab\'s linkedin page',
        imagePath: '{{mediaUrlPrefix}}/Footer/Social-Media-Icons/linkedin-icon.svg',
        href: 'https://www.linkedin.com/company/geotab',
        type: 'socialMediaLink',
    },
    {
        title: 'Go to Geotab\'s youtube page',
        imagePath: '{{mediaUrlPrefix}}/Footer/Social-Media-Icons/youtube-icon.svg',
        href: 'https://www.youtube.com/user/MyGeotab',
        type: 'socialMediaLink',
    },
];

export const topFooterContentTypes = {
    sectionLink: {
        type: 'sectionLink',
        title: '',
        links: [
            {
                title: 'new link',
                href: '',
                isTargetToNewTab: false,
            },
        ],
        column: 1,
    },
    imageLink: {
        type: 'imageLink',
        title: '',
        imagePath: '',
        imageAlt: '',
        href: '',
        ariaLabel: '',
        isTargetToNewTab: false,
        column: 1,
    },
};

export const bottomFooterContentTypes = {
    link: {
        type: 'link',
        title: 'new link',
        href: '',
        isTargetToNewTab: true,
        rel: '',
    },
};

type FooterSocialMediaLinkType = {
    socialMediaLink: FooterSocialMediaLink;
};

export const footerSocialMediaLinkTypes: FooterSocialMediaLinkType = {
    socialMediaLink: {
        title: '',
        imagePath: '',
        href: '',
        type: 'socialMediaLink',
    },
};

export const footerLink = {
    link: {
        title: 'new link',
        href: '',
        isTargetToNewTab: false,
    },
};

export const singleColumnTypes = {
    withImage: {
        title: '',
        description: '',
        links: [
            {
                href: '',
                title: '',
                isTargetToNewTab: false,
            },
        ],
        image: {
            imageUrl: '',
            imageAlt: '',
        },
        type: 'withImage',
    },
    withDescriptionLinks: {
        title: '',
        links: [
            {
                href: '',
                title: '',
                description: '',
                isTargetToNewTab: false,
            },
        ],
        type: 'withDescriptionLinks',
    },
};

export const twoColumnTypes = {
    withLinks: {
        title: '',
        links: [
            {
                href: '',
                title: '',
                isTargetToNewTab: false,
                variant: 'primary',
            },
        ],
        isGrouped: false,
        type: 'withLinks',
    },
    withDescriptionLinks: {
        title: '',
        links: [
            {
                href: '',
                title: '',
                description: '',
                isTargetToNewTab: false,
            },
        ],
        type: 'withDescriptionLinks',
    },
    withLinksAndImage: {
        title: '',
        links: [
            {
                href: '',
                title: '',
                isTargetToNewTab: false,
                variant: 'primary',
            },
        ],
        image: {
            imageUrl: '',
            imageAlt: '',
        },
        type: 'withLinksAndImage',
    },
};

export const columnTypes = {
    singleColumn: {
        content: singleColumnTypes.withDescriptionLinks,
        type: 'singleColumn',
    },
    twoColumn: {
        leftContent: twoColumnTypes.withLinksAndImage,
        rightContent: twoColumnTypes.withLinks,
        hasDivider: false,
        type: 'twoColumn',
    },
};

export const dropDown = {
    title: 'Add drop',
    leftColumn: columnTypes.singleColumn,
    rightColumn: columnTypes.twoColumn,
};

export const mainFormContentTypes = {
    link: {
        type: 'link',
        content: {
            title: 'link text',
            href: '',
            isTargetToNewTab: false,
        },
    },
    button: {
        type: 'button',
        content: {
            title: 'button text',
            variant: 'contained',
            href: '',
        },
    },
    dropdown: {
        type: 'dropdown',
        content: dropDown,
    },
    'popout-button': {
        type: 'popout-button',
        content: {
            title: 'Popout button',
            variant: 'contained',
            customSection: null,
            params: [],
        },
    },
};

// v2

type NavbarButtonContent<ContentType extends string> = { type: ContentType; name: string };

type NavbarContent<ContentItem extends { type: string }> = Record<ContentItem['type'], ContentItem>;

export const mainLeftContent: NavbarButtonContent<NavbarLeftMainContentType>[] = [
    { type: 'dropdown', name: 'Add dropdown' },
    { type: 'link', name: 'Add link' },
];

export const mainRightContent: NavbarButtonContent<NavbarRightMainContentType>[] = [
    { type: 'popoutButton', name: ' Add popout button' },
    { type: 'buttonLink', name: 'Add link' },
];

export const dropDownContent: NavbarButtonContent<NavbarDropDownColumnType>[] = [
    { type: 'heading', name: 'Add heading' },
    { type: 'link', name: 'Add link' },
    { type: 'dropdownLink', name: 'Add dropdown link' },
    { type: 'callToAction', name: 'Add call to action' },
    { type: 'smallLink', name: 'Add small link' },
];

export const dropDownV2: NavbarContent<NavbarDropDownColumn> = {
    heading: {
        type: 'heading',
        content: {
            title: 'heading text',
        },
    },
    link: {
        type: 'link',
        content: {
            title: 'link text',
            href: '',
        },
    },
    dropdownLink: {
        type: 'dropdownLink',
        content: {
            title: 'link text',
            href: '',
            description: '',
            icon: {
                imagePath: '',
                imageAlt: '',
            },
        },
    },
    callToAction: {
        type: 'callToAction',
        content: {
            imagePath: '',
            imageAlt: '',
            headingText: 'heading text',
            description: '',
            linkText: 'link text',
            href: '',
        },
    },
    smallLink: {
        type: 'smallLink',
        content: {
            title: 'link text',
            href: '',
        },
    },
};

export const mainFormLeftContentTypes: NavbarContent<NavbarLeftMainContentItem> = {
    link: {
        type: 'link',
        content: {
            title: 'link text',
            href: '',
        },
    },
    dropdown: {
        type: 'dropdown',
        content: {
            title: 'dropdown title',
            columns: [],
        },
    },
};

export const mainFormRightContentTypes: NavbarContent<NavbarRightMainContentItem> = {
    buttonLink: {
        type: 'buttonLink',
        content: {
            title: 'link text',
            variant: 'contained',
            href: '',
        },
    },
    popoutButton: {
        type: 'popoutButton',
        content: {
            title: 'Popout button',
            variant: 'contained',
        },
    },
};

export const microFormContentTypesV2: NavbarContent<NavbarLink> = {
    link: {
        type: 'link',
        content: {
            title: 'link text',
            href: '',
        },
    },
};

export const defaultNavbar: MenuNavigation = {
    isLanguageDefault: true,
    id: 1,
    language: 1,
    name: 'Default Navbar',
    showSearch: true,
    showLanguageRegion: true,
    secondaryLogo: null,
    secondaryLogoLink: null,
    secondaryLogoLabel: null,
    secondaryLogoAlt: null,
    version: 1,
    isMicroNavVisible: true,
    showLoginButton: null,
    loginText: null,
    mainContent: [
        {
            type: 'dropdown',
            content: {
                title: 'Products',
                leftColumn: {
                    content: {
                        title: 'Geotab GO device',
                        description:
                            'Plug this small vehicle tracking device into your vehicle’s OBDII port and start collecting rich data in minutes.',
                        links: [
                            {
                                href: '/vehicle-tracking-device/',
                                title: 'Learn more',
                                isTargetToNewTab: false,
                            },
                        ],
                        image: {
                            imageUrl: dropdownProducts,
                            imageAlt: '',
                        },
                        type: 'withImage',
                    },
                    type: 'singleColumn',
                },
                rightColumn: {
                    leftContent: {
                        title: 'More Geotab products',
                        links: [
                            {
                                href: '/fleet-management-software/',
                                title: 'MyGeotab',
                                description:
                                    'Web-based fleet management software that displays all your vehicle and driver information. Use it to make quicker, better-informed decisions.',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: 'https://marketplace.geotab.com/',
                                title: 'Marketplace',
                                description:
                                    'Ecosystem of third-party mobile apps, software Add-Ins, and hardware Add-Ons leveraging our open platform to accelerate innovation.',
                                isTargetToNewTab: true,
                                variant: 'primary',
                            },
                            {
                                href: 'https://data.geotab.com/',
                                title: 'Data and Analytics',
                                description:
                                    'Empower your organization with rich data analysis from hundreds of thousands of vehicles across the globe.',
                                isTargetToNewTab: true,
                                variant: 'primary',
                            },
                        ],
                        type: 'withDescriptionLinks',
                    },
                    rightContent: {
                        title: 'Partners',
                        links: [
                            {
                                href: '/partners/overview/',
                                title: 'Partner overview',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/partners/platform-partners/',
                                title: 'Platform Partners',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/become-installer/',
                                title: 'Become an installer',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                        ],
                        isGrouped: false,
                        type: 'withLinks',
                    },
                    hasDivider: false,
                    type: 'twoColumn',
                },
            },
        },
        {
            type: 'dropdown',
            content: {
                title: 'Solutions',
                leftColumn: {
                    leftContent: {
                        title: 'Fleet Management',
                        links: [
                            {
                                href: '/fleet-management-solutions/productivity/',
                                title: 'Productivity',
                                isTargetToNewTab: false,
                                variant: 'groupTitle',
                            },
                            {
                                href: '/fleet-management-solutions/asset-tracking/',
                                title: 'Asset tracking and management',
                                isTargetToNewTab: false,
                                variant: 'secondary',
                            },
                            {
                                href: '/fleet-management-solutions/driver-tracking/',
                                title: 'Driver tracking',
                                isTargetToNewTab: false,
                                variant: 'secondary',
                            },
                            {
                                href: '/fleet-management-solutions/routing-dispatching/',
                                title: 'Routing and dispatching',
                                isTargetToNewTab: false,
                                variant: 'secondary',
                            },
                            {
                                href: '/fleet-management-solutions/fleet-management-reports/',
                                title: 'Fleet reports',
                                isTargetToNewTab: false,
                                variant: 'secondary',
                            },
                            {
                                href: '/fleet-management-solutions/fleet-optimization/',
                                title: 'Optimization',
                                isTargetToNewTab: false,
                                variant: 'groupTitle',
                            },
                            {
                                href: '/fleet-management-solutions/fleet-fuel-management/',
                                title: 'Fleet fuel management',
                                isTargetToNewTab: false,
                                variant: 'secondary',
                            },
                            {
                                href: '/fleet-management-solutions/fleet-maintenance/',
                                title: 'Fleet maintenance',
                                isTargetToNewTab: false,
                                variant: 'secondary',
                            },
                            {
                                href: '/fleet-management-solutions/fleet-benchmarking/',
                                title: 'Fleet benchmarking',
                                isTargetToNewTab: false,
                                variant: 'secondary',
                            },
                            {
                                href: '/fleet-management-solutions/fleet-safety/',
                                title: 'Safety',
                                isTargetToNewTab: false,
                                variant: 'groupTitle',
                            },
                            {
                                href: '/fleet-management-solutions/driver-safety-reports/',
                                title: 'Driver safety reporting',
                                isTargetToNewTab: false,
                                variant: 'secondary',
                            },
                            {
                                href: '/fleet-management-solutions/driver-coaching/',
                                title: 'Driver coaching',
                                isTargetToNewTab: false,
                                variant: 'secondary',
                            },
                            {
                                href: '/fleet-management-solutions/fleet-dash-cams/',
                                title: 'Dash cameras',
                                isTargetToNewTab: false,
                                variant: 'secondary',
                            },
                        ],
                        isGrouped: true,
                        type: 'withLinks',
                    },
                    rightContent: {
                        title: '',
                        links: [
                            {
                                href: '/fleet-management-solutions/sustainability/',
                                title: 'Sustainability',
                                isTargetToNewTab: false,
                                variant: 'groupTitleForcedMargin',
                            },
                            {
                                href: '/fleet-management-solutions/electric-vehicles/',
                                title: 'Electric vehicle fleets',
                                isTargetToNewTab: false,
                                variant: 'secondary',
                            },
                            {
                                href: '/fleet-management-solutions/evsa/',
                                title: 'EV Suitability Assessment',
                                isTargetToNewTab: false,
                                variant: 'secondary',
                            },
                            {
                                href: '/fleet-management-solutions/compliance/',
                                title: 'Compliance',
                                isTargetToNewTab: false,
                                variant: 'groupTitle',
                            },
                            {
                                href: '/fleet-management-solutions/eld/',
                                title: 'ELD',
                                isTargetToNewTab: false,
                                variant: 'secondary',
                            },
                            {
                                href: '/fleet-management-solutions/dvir/',
                                title: 'DVIR',
                                isTargetToNewTab: false,
                                variant: 'secondary',
                            },
                            {
                                href: '/fleet-management-solutions/ifta/',
                                title: 'IFTA',
                                isTargetToNewTab: false,
                                variant: 'secondary',
                            },
                            {
                                href: '/fleet-management-solutions/expandability/',
                                title: 'Expandability',
                                isTargetToNewTab: false,
                                variant: 'groupTitle',
                            },
                            {
                                href: '/fleet-management-solutions/software-integration/',
                                title: 'Software integration',
                                isTargetToNewTab: false,
                                variant: 'secondary',
                            },
                            {
                                href: '/fleet-management-solutions/hardware-integration/',
                                title: 'Hardware integration',
                                isTargetToNewTab: false,
                                variant: 'secondary',
                            },
                        ],
                        isGrouped: true,
                        type: 'withLinks',
                    },
                    hasDivider: false,
                    type: 'twoColumn',
                },
                rightColumn: {
                    leftContent: {
                        title: 'Industries',
                        links: [
                            {
                                href: '/industries/construction-fleet/',
                                title: 'Construction fleet',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/industries/pharmaceutical-fleet/',
                                title: 'Pharmaceutical fleet',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/industries/police-first-responders-fleet/',
                                title: 'Police and first responders',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/industries/heavy-truck-fleet/',
                                title: 'Heavy truck',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/industries/utility-fleet/',
                                title: 'Utilities',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/industries/waste-recycling-fleet/',
                                title: 'Waste and recycling',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/industries/food-beverage-fleet/',
                                title: 'Food and beverage',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/industries/oil-gas-mining/',
                                title: 'Oil, gas and mining',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/industries/winter-operations/',
                                title: 'Winter operations',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/industries/courier-delivery-fleet/',
                                title: 'Courier and delivery',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/industries/',
                                title: 'See all industries',
                                isTargetToNewTab: false,
                                variant: 'tertiary',
                            },
                        ],
                        type: 'withLinks',
                    },
                    rightContent: {
                        title: 'Government and Smart City',
                        links: [
                            {
                                href: '/government/',
                                title: 'Government fleets',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/smart-city-solutions/',
                                title: 'Smart City solutions',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/public-works/',
                                title: 'Public Works solutions',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                        ],
                        image: {
                            imageUrl: dropdownSolutions,
                            imageAlt: '',
                        },
                        type: 'withLinksAndImage',
                    },
                    hasDivider: true,
                    type: 'twoColumn',
                },
            },
        },
        {
            type: 'dropdown',
            content: {
                title: 'Resources',
                leftColumn: {
                    content: {
                        title: 'Geotab blog',
                        description:
                            'Get the latest on Geotab and the telematics industry. Join over 30,000 subscribers.',
                        links: [
                            {
                                href: '/blog/',
                                title: 'Visit the blog',
                                isTargetToNewTab: false,
                            },
                        ],
                        image: {
                            imageUrl: dropdownResources,
                            imageAlt: '',
                        },
                        type: 'withImage',
                    },
                    type: 'singleColumn',
                },
                rightColumn: {
                    content: {
                        title: 'More resources',
                        links: [
                            {
                                href: '/white-papers/',
                                title: 'White papers',
                                description:
                                    'Download our latest white papers for key insights into the telematics industry',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/success-stories/',
                                title: 'Success stories',
                                description:
                                    'Discover how industry leaders are using Geotab telematics solutions to improve all aspects of their business',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/training/',
                                title: 'Training',
                                description:
                                    'Sign up for our next live webinar or watch one of our many archived how-to videos',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/press-releases/',
                                title: 'Press room',
                                description: 'Stay up to date with Geotab news and announcements.',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                        ],
                        type: 'withDescriptionLinks',
                    },
                    type: 'singleColumn',
                },
            },
        },
        {
            type: 'dropdown',
            content: {
                title: 'Support',
                leftColumn: {
                    content: {
                        title: 'Community',
                        description:
                            'Find answers, interact with other members to get help, share ideas and build knowledge',
                        links: [
                            {
                                href: 'https://community.geotab.com/s/',
                                title: 'Ask the community',
                                isTargetToNewTab: true,
                            },
                        ],
                        image: {
                            imageUrl: dropdownSupport,
                            imageAlt: '',
                        },
                        type: 'withImage',
                    },
                    type: 'singleColumn',
                },
                rightColumn: {
                    leftContent: {
                        title: 'More support resources',
                        links: [
                            {
                                href: 'https://community.geotab.com/s/knowledge',
                                title: 'Support Knowledge Base',
                                description:
                                    'Browse through solutions, how-to and Q&A articles written by Geotab Support teams',
                                isTargetToNewTab: true,
                            },
                            {
                                href: '/support-documentation/',
                                title: 'Support documentation',
                                description: 'Documentation for our physical devices and firmware',
                                isTargetToNewTab: false,
                            },
                            {
                                href: '/installation-documentation/',
                                title: 'Installation documentation',
                                description:
                                    'Documentation for GO devices and harnesses. Apply to become a Geotab installer or find an Authorized Installer in your area',
                                isTargetToNewTab: false,
                            },
                        ],
                        type: 'withDescriptionLinks',
                    },
                    rightContent: {
                        title: 'More support resources',
                        links: [
                            {
                                href: 'https://docs.google.com/document/d/14GNbMq_ZKSUpkmSdJ8ws-DyO4P_HmTYOIu3k52f7KKY/edit#heading=h.2m3lq1tf5pe1',
                                title: 'Product guide',
                                description:
                                    'Get the most out of your telematics platform with our product guide. Updated continuously as new features are added',
                                isTargetToNewTab: true,
                            },
                        ],
                        type: 'withDescriptionLinks',
                    },
                    hasDivider: false,
                    type: 'twoColumn',
                },
            },
        },
        {
            type: 'dropdown',
            content: {
                title: 'About',
                leftColumn: {
                    content: {
                        title: 'Contact us',
                        description: 'Send us a message, give us a call, or view our global office locations',
                        links: [
                            {
                                href: '/contact-us/',
                                title: 'Get in touch',
                                isTargetToNewTab: false,
                            },
                        ],
                        image: {
                            imageUrl: dropdownAbout,
                            imageAlt: '',
                        },
                        type: 'withImage',
                    },
                    type: 'singleColumn',
                },
                rightColumn: {
                    leftContent: {
                        title: 'The Geotab advantage',
                        links: [
                            {
                                href: '/about/',
                                title: 'About us',
                                description: 'Empowering business growth with insight',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: 'https://careers.geotab.com',
                                title: 'Careers',
                                description: 'We’re hiring! Where passion for innovation meets creativity.',
                                isTargetToNewTab: true,
                                variant: 'primary',
                            },
                            {
                                href: '/about/leadership/',
                                title: 'Leadership',
                                description: 'Meet Geotab’s corporate leadership team',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                        ],
                        type: 'withDescriptionLinks',
                    },
                    rightContent: {
                        title: '',
                        links: [
                            {
                                href: '/about/corporate-social-responsibility/',
                                title: 'Social responsibility',
                                description: 'Leading by example',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/security/',
                                title: 'Security',
                                description:
                                    'Protecting our customers\' valuable data with our "Security First" principle',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                            {
                                href: '/events/',
                                title: 'Events',
                                description: 'Register now for an event near you!',
                                isTargetToNewTab: false,
                                variant: 'primary',
                            },
                        ],
                        type: 'withDescriptionLinks',
                    },
                    hasDivider: false,
                    type: 'twoColumn',
                },
            },
        },
        {
            content: {
                href: '/request-demo',
                title: 'Request a demo',
                variant: 'contained',
            },
            type: 'button',
        },
        {
            content: {
                href: '/mygeotab-login',
                title: 'Login',
                variant: 'outlined',
            },
            type: 'button',
        },
    ],
    microContent: [
        {
            type: 'link',
            content: {
                href: '/contact-us',
                title: 'Contact Us',
                isTargetToNewTab: false,
            },
        },
    ],
};

export const defaultFooter: FooterNavigation = {
    isLanguageDefault: true,
    id: 1,
    language: 1,
    name: 'Default Footer',
    copyRightText: 'Copyright {{year}}. Geotab Inc. All Rights Reserved',
    showLanguageRegion: true,
    backgroundColor: null,
    socialMediaLinks: [],
    topContent: [
        {
            type: 'sectionLink',
            title: 'Products',
            links: [
                {
                    title: 'Geotab GO device',
                    isTargetToNewTab: false,
                    href: '/vehicle-tracking-device/',
                },
                { title: 'MyGeotab', isTargetToNewTab: false, href: '/fleet-management-software/' },
                {
                    title: 'Marketplace',
                    isTargetToNewTab: false,
                    href: 'https://marketplace.geotab.com/',
                },
                {
                    title: 'Data and Analytics',
                    isTargetToNewTab: false,
                    href: 'https://data.geotab.com/',
                },
            ],
            column: 1,
        },
        {
            type: 'sectionLink',
            title: 'Resources',
            links: [
                { title: 'Blog', isTargetToNewTab: false, href: '/blog/' },
                { title: 'White papers', isTargetToNewTab: false, href: '/white-papers/' },
                { title: 'Success stories', isTargetToNewTab: false, href: '/success-stories/' },
                { title: 'Press room', isTargetToNewTab: false, href: '/press-releases/' },
                { title: 'Training', isTargetToNewTab: false, href: '/training/' },
                { title: 'Subscribe', isTargetToNewTab: false, href: '/subscribe/' },
            ],
            column: 1,
        },
        {
            type: 'sectionLink',
            title: 'Government and smart City',
            links: [
                { title: 'Government fleets', isTargetToNewTab: false, href: '/government/' },
                {
                    title: 'Smart City solutions',
                    isTargetToNewTab: false,
                    href: '/smart-city-solutions/',
                },
                {
                    title: 'Public Works solutions',
                    isTargetToNewTab: false,
                    href: '/public-works/',
                },
            ],
            column: 1,
        },
        {
            type: 'sectionLink',
            title: 'Fleet management solutions',
            links: [
                {
                    title: 'Driver tracking',
                    isTargetToNewTab: false,
                    href: '/fleet-management-solutions/driver-tracking/',
                },
                {
                    title: 'Fleet reporting',
                    isTargetToNewTab: false,
                    href: '/fleet-management-solutions/fleet-management-reports/',
                },
                {
                    title: 'Routing and dispatching',
                    isTargetToNewTab: false,
                    href: '/fleet-management-solutions/routing-dispatching/',
                },
                {
                    title: 'Asset tracking',
                    isTargetToNewTab: false,
                    href: '/fleet-management-solutions/asset-tracking/',
                },
                {
                    title: 'Fleet fuel management',
                    isTargetToNewTab: false,
                    href: '/fleet-management-solutions/fleet-fuel-management/',
                },
                {
                    title: 'Fleet maintenance',
                    isTargetToNewTab: false,
                    href: '/fleet-management-solutions/fleet-maintenance/',
                },
                {
                    title: 'Fleet benchmarking',
                    isTargetToNewTab: false,
                    href: '/fleet-management-solutions/fleet-benchmarking/',
                },
                {
                    title: 'Driver safety reporting',
                    isTargetToNewTab: false,
                    href: '/fleet-management-solutions/driver-safety-reports/',
                },
                {
                    title: 'Driver coaching',
                    isTargetToNewTab: false,
                    href: '/fleet-management-solutions/driver-coaching/',
                },
                { title: 'ELD', isTargetToNewTab: false, href: '/fleet-management-solutions/eld/' },
                {
                    title: 'DVIR',
                    isTargetToNewTab: false,
                    href: '/fleet-management-solutions/dvir/',
                },
                {
                    title: 'IFTA',
                    isTargetToNewTab: false,
                    href: '/fleet-management-solutions/ifta/',
                },
                {
                    title: 'Software integration',
                    isTargetToNewTab: false,
                    href: '/fleet-management-solutions/software-integration/',
                },
                {
                    title: 'Hardware integration',
                    isTargetToNewTab: false,
                    href: '/fleet-management-solutions/hardware-integration/',
                },
                {
                    title: 'EV Suitability Assessment',
                    isTargetToNewTab: false,
                    href: '/fleet-management-solutions/evsa/',
                },
                {
                    title: 'Electric vehicle fleets',
                    isTargetToNewTab: false,
                    href: '/fleet-management-solutions/electric-vehicles/',
                },
            ],
            column: 2,
        },
        {
            type: 'sectionLink',
            title: 'Industries',
            links: [
                {
                    title: 'Construction',
                    isTargetToNewTab: false,
                    href: '/industries/construction-fleet/',
                },
                {
                    title: 'Courier and delivery',
                    isTargetToNewTab: false,
                    href: '/industries/courier-delivery-fleet/',
                },
                {
                    title: 'Field sales and services',
                    isTargetToNewTab: false,
                    href: '/industries/field-sales-service/',
                },
                {
                    title: 'Food and beverage',
                    isTargetToNewTab: false,
                    href: '/industries/food-beverage-fleet/',
                },
                {
                    title: 'Heavy truck',
                    isTargetToNewTab: false,
                    href: '/industries/heavy-truck-fleet/',
                },
                {
                    title: 'Landscaping',
                    isTargetToNewTab: false,
                    href: '/industries/landscaping-fleet/',
                },
                { title: 'Mobility', isTargetToNewTab: false, href: '/industries/mobility-fleet/' },
                {
                    title: 'Oil, gas and mining',
                    isTargetToNewTab: false,
                    href: '/industries/oil-gas-mining/',
                },
                {
                    title: 'Pharmaceutical',
                    isTargetToNewTab: false,
                    href: '/industries/pharmaceutical-fleet/',
                },
                {
                    title: 'Police and first responders',
                    isTargetToNewTab: false,
                    href: '/industries/police-first-responders-fleet/',
                },
                { title: 'Rental', isTargetToNewTab: false, href: '/industries/rental-fleet/' },
                { title: 'Towing', isTargetToNewTab: false, href: '/industries/towing-fleet/' },
                { title: 'Utilities', isTargetToNewTab: false, href: '/industries/utility-fleet/' },
                {
                    title: 'Waste and recycling',
                    isTargetToNewTab: false,
                    href: '/industries/waste-recycling-fleet/',
                },
                {
                    title: 'Winter operations',
                    isTargetToNewTab: false,
                    href: '/industries/winter-operations/',
                },
            ],
            column: 3,
        },
        {
            type: 'sectionLink',
            title: 'Our company',
            links: [
                { title: 'About', isTargetToNewTab: false, href: '/about/' },
                { title: 'Careers', isTargetToNewTab: false, href: 'https://careers.geotab.com/' },
                { title: 'Leadership', isTargetToNewTab: false, href: '/about/leadership/' },
                {
                    title: 'CSR',
                    isTargetToNewTab: false,
                    href: '/about/corporate-social-responsibility/',
                },
                { title: 'Security', isTargetToNewTab: false, href: '/security/' },
                { title: 'Partners', isTargetToNewTab: false, href: '/partners/overview/' },
                {
                    title: 'Platform Partners',
                    isTargetToNewTab: false,
                    href: '/partners/platform-partners/',
                },
                { title: 'Accessibility', isTargetToNewTab: false, href: '/accessibility/' },
            ],
            column: 4,
        },
        {
            type: 'sectionLink',
            title: 'Support',
            links: [
                { title: 'FAQ', isTargetToNewTab: false, href: '/faq/' },
                {
                    title: 'Product guide',
                    isTargetToNewTab: true,
                    href: 'https://docs.google.com/document/d/14GNbMq_ZKSUpkmSdJ8ws-DyO4P_HmTYOIu3k52f7KKY/edit#heading=h.2m3lq1tf5pe1',
                },
                {
                    title: 'Support documentation',
                    isTargetToNewTab: false,
                    href: '/support-documentation/',
                },
                {
                    title: 'Installation documentation',
                    isTargetToNewTab: false,
                    href: '/installation-documentation/',
                },
                {
                    title: 'Community',
                    isTargetToNewTab: true,
                    href: 'https://community.geotab.com/s/',
                },
            ],
            column: 4,
        },
    ],
    bottomContent: [
        {
            type: 'link',
            title: 'Privacy Policy',
            href: 'https://docs.google.com/document/d/1sVygLN02w2xNovFY4q5vw-oAzfYxCd7WLhyToElgDbs/pub',
            rel: 'noopener noreferrer',
            isTargetToNewTab: true,
        },
        {
            type: 'link',
            title: 'Cookies',
            href: 'https://docs.google.com/document/d/16WpzHxVSGhIliCQuD0HMY4mGgGIxYRkwq0c2BB1TMOI/pub',
            rel: 'noopener noreferrer',
            isTargetToNewTab: true,
        },
        {
            type: 'link',
            title: 'Sitemap',
            href: 'https://www.geotab.com/sitemap/',
            rel: '',
            isTargetToNewTab: true,
        },
        {
            type: 'link',
            title: 'MySubscriptions',
            href: 'https://www.geotab.com/subscribe/',
            rel: '',
            isTargetToNewTab: true,
        },
        {
            type: 'link',
            title: 'MyAdmin',
            href: 'https://myadmin.geotab.com/login?returnUrl=%2Fv1',
            rel: '',
            isTargetToNewTab: true,
        },
        {
            type: 'link',
            title: 'End User Agreement',
            href: 'https://docs.google.com/document/d/1jA8Qc8WZMhmaqdjd8Ng8rY4iMI073xCfLNt_DSP72TQ/edit',
            rel: 'noopener noreferrer',
            isTargetToNewTab: true,
        },
        {
            type: 'link',
            title: 'Modern Slavery Statement',
            href: 'https://www.geotab.com/modern-slavery-statement/',
            rel: 'noopener noreferrer',
            isTargetToNewTab: true,
        },
    ],
};
