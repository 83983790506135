import { enSoftwarePackages } from './pages/softwarePackages/en.softwarePackages';
import { PressRoomImages } from '@helpers/staticImages/pressRoom';
import { contactUs, subscribePage, videos } from '@helpers/staticImages/staticPages';

export const en = {
    badRequest: {
        title: '400 Bad Request | Geotab',
        heading: 'Oh no!',
        description:
            'There seems to be a client error. Make sure the request you are making or page you are trying to reach is valid.',
        description2: 'Try searching or go back to',
        homepageLink: 'our homepage',
    },
    notFound: {
        title: '404 Not Found | Geotab',
        heading: 'Oh no!',
        description: 'The page you were looking for could not be found.',
        description2: 'Try searching or go back to',
        homepageLink: 'our homepage',
    },
    successStoriesTemplate: {
        publishedOn: 'Published on',
        clientProfile: 'Client profile',
        name: 'Client name',
        industry: 'Industry',
        typesOfVehicles: 'Types of vehicles',
        fleetSize: 'Fleet size',
        fleetFocus: 'Fleet focus',
        otherStories: 'Other stories',
        optimizeYourFleet: 'Ready to optimize your fleet?',
        letUsShowYou:
            'Let us show you how simple it is to use our web-based software and fleet tracking devices to manage your fleet.',
        requestDemo: 'Request a demo',
        requestDemoPath: '/request-demo/',
        visitBlog: 'Visit the blog',
        downloadSuccessStory: 'Download PDF',
    },
    softwarePackages: enSoftwarePackages,
    categories: {
        all: 'all',
        allCategoriesTitleTag: 'View all blog posts.',
        categoriesTitleTag: 'View posts in',
        geotabAuthor: 'Geotab author',
        page: 'Page',
        fleetManagementBlog: 'Fleet Management Blog',
    },
    blogHome: {
        seo: {
            title: 'Geotab Blog | Fleet Management Blog',
            description: 'Read the latest fleet management solution updates and technology | Fleet Management Blog',
        },
        featured: 'Featured',
        browseCategories: 'Browse categories',
        editorsPicks: 'Editor\'s picks',
        newestPosts: 'Newest Posts',
        viewAll: 'View all',
    },
    whitePapersHome: {
        seo: {
            title: 'Fleet Management Whitepaper Reads',
            description:
                'The latest Whitepapers from Geotab. Learn how Geotab`s end to end fleet management solution can help your business.',
        },
        page: 'Page',
        title: 'White papers',
    },
    whitePaper: {
        articleCard: {
            publishedOn: 'Published on',
            downloadWhitePaper: 'Download white paper',
        },
        allWhitePapers: 'All white papers',
    },
    pressRelease: {
        publishedOn: 'Published on',
        mediaContact: {
            title: 'Media Contact',
            name: 'Nicole Riddle',
            role: 'Senior Specialist, Strategic Communications',
            email: 'pr@geotab.com',
            imageAlt: 'A graphic showing a white megaphone.',
        },
    },
    pressRoomHome: {
        seo: {
            title: 'Press room and Media News',
            description:
                'View the latest telematics and fleet management news and Geotab updates in the Geotab Press room and Media News.',
        },
        allPressReleases: 'All press releases',
        pressKit: {
            pressKitTitle: 'Press Kit',
            ourAvailableResources: 'Our available resources for journalists. For media inquiries, email: ',
            pressKitList: [
                {
                    name: 'Corporate profile',
                    url: '/about/',
                    image: PressRoomImages.corpProfile,
                },
                {
                    name: 'Leadership bios',
                    url: '/about/leadership/',
                    image: PressRoomImages.leadershipBio,
                },
                {
                    name: 'Fact sheet',
                    url: 'https://docs.google.com/document/d/1Bct3-Z9FCtl15C7DS8iGWtoE7Ckc6IgvRbv9cRqmYFw/edit?usp=sharing',
                    image: PressRoomImages.mediaGuidelines,
                },
                {
                    name: 'Third party press release policy',
                    url: 'https://docs.google.com/document/d/1op_dyBGG99rBjB4GEcV8vArEqrXKo7pqoNG3l-kuqkY/edit?usp=sharing',
                    image: PressRoomImages.legalPolicy,
                },
            ],
        },
        pressRoomSectionListCards: {
            latestUpdates: 'Latest updates',
            inOtherNews: 'In Other News',
        },
    },
    pagination: {
        page: 'Page',
        of: 'of',
    },
    articleTypes: {
        pressRelease: 'Media release',
        caseStudy: 'Case study',
        blog: 'Blog',
        whitePaper: 'White paper',
        video: 'Video',
        glossary: 'Glossary',
    },
    articleTypesAction: {
        viewAll: 'View all',
        filter: 'Filter',
    },
    successStoriesHome: {
        seo: {
            title: 'Case Studies, Use Cases & Success Stories | Geotab',
            description:
                'Discover how companies have succeeded with Geotab Fleet Management Software. View our fleet management case studies and GPS fleet management success stories.',
        },
        header: 'Success stories',
        bannerDescription:
            'Learn how customers are using Geotab to achieve their goals in safety, sustainability, productivity and cost reduction.',
        filters: 'Filters',
        applyFilter: 'Apply Filter',
        subHeader: 'Filter by',
        sortBy: 'Sort by',
        clearFilter: 'Clear filters',
        results: 'Results',
        notResult: 'We can\'t find any articles that match this criteria. Try changing the filters for more results',
        industry: 'Industry',
        region: 'Region',
        fleetSize: 'Fleet size',
        fleetFocus: 'Fleet focus',
        fleets: 'fleets',
        fleetSizeFilterList: [
            { content: 'All', value: -1 },
            { content: 'Small', value: 0 },
            { content: 'Medium', value: 1 },
            { content: 'Large', value: 2 },
        ],
        fleetRegionFilterList: [
            { content: 'All', value: -1 },
            { content: 'North America', value: 0 },
            { content: 'Latin America', value: 1 },
            { content: 'Europe', value: 2 },
        ],
        fleetIndustryFilterList: [
            { content: 'All', value: -1 },
            { content: 'Bus and Passenger', value: 0 },
            { content: 'Construction', value: 1 },
            { content: 'Courier and Delivery', value: 2 },
            { content: 'Field sales and services', value: 3 },
            { content: 'Food and beverage', value: 4 },
            { content: 'Government', value: 5 },
            { content: 'Oil, Gas, and Mining', value: 6 },
            { content: 'Pharmaceutical', value: 7 },
            { content: 'Police and first responders', value: 8 },
            { content: 'Rental (Vehicle Sharing)', value: 9 },
            { content: 'Towing', value: 10 },
            { content: 'Utilities', value: 11 },
            { content: 'Waste and Recycling', value: 12 },
            { content: 'Winter Operations', value: 13 },
            { content: 'Telecommunications', value: 14 },
            { content: 'Transportation and Logistics', value: 15 },
        ],
        sortByFilterList: [
            { content: 'Newest', value: 0 },
            { content: 'Oldest', value: 1 },
            { content: 'Title', value: 2 },
        ],
        notFoundHeading: 'No results found',
        notFoundInfoLines: [
            'We can\'t find any articles that match this criteria.',
            'Try changing the filters for more results',
        ],
    },
    aboutGeotab: {
        main: 'About Geotab',
        content:
            'Geotab, the global leader in connected vehicle and asset solutions, leverages advanced data analytics and AI to enhance fleet performance, safety, and sustainability while optimizing costs. Backed by a team of industry leading data scientists, engineers and AI experts, we serve over 50,000 customers across 160 countries, processing billions of data points hourly from more than 4 million vehicles. Data security and privacy are at the forefront of all we do—trusted by Fortune 500 organizations and some of the largest public sector fleets in the world, we meet top cybersecurity standards. Geotab’s open platform and diverse Geotab Marketplace offers hundreds of fleet-ready third-party solutions. Learn more at <a href="https://www.geotab.com/" target="_blank">www.geotab.com</a> and follow us on <a href="https://www.linkedin.com/company/geotab/" target="_blank" rel="noopener noreferrer">LinkedIn</a> or visit <a href="https://www.geotab.com/news-and-views/" target="_blank" rel="noopener noreferrer">Geotab News and Views</a>.',
        rights: 'All Rights Reserved.',
        disclaimer:
            'This white paper is intended to provide information and encourage discussion on topics of interest to the telematics community. Geotab is not providing technical, professional or legal advice through this white paper. While every effort has been made to ensure that the information in this white paper is timely and accurate, errors and omissions may occur, and the information presented here may become out-of-date with the passage of time.',
    },
    recentNews: 'Recent News',
    relatedPosts: 'Related posts',
    renderChips: {
        postTags: 'Post Tags',
        tag: 'tag',
    },
    regionSelection: {
        seo: {
            title: 'Region Selection',
            description:
                'Select your country or region to learn about Geotab\'s fleet management software and vehicle tracking devices.',
        },
        title: 'Select region/language',
        regions: {
            global: 'Global',
            northSouthAmerica: 'Americas',
            europe: 'Europe',
            asiaAustraliaMiddleEast: 'Asia, Australia & Middle East',
        },
        regionChangeText: 'Change language',
    },
    contactUsForm: {
        firstName: 'First name',
        lastName: 'Last name',
        phone: 'Phone',
        email: 'Email',
        company: 'Company',
        fleetSize: 'Fleet Size',
        country: 'Country',
        city: 'City',
        comments: 'Questions/Comments:',
        checkboxText:
            'Yes, I would like to receive marketing communications regarding Geotab products, services, newsletters, and events. Your information will be used in accordance with Geotab’s privacy policy.',
        submit: 'Submit',
        languageCode: 'English Global',
        consentTextEU:
            'Would like to receive marketing communications regarding Geotab.com products, services, and events. Your information will be used in accordance with Geotab’s privacy policy.',
        yes: 'Yes',
        no: 'No',
        companyUrl: 'Company Website',
        resellerComment: 'Additional company details:',
    },
    resellerForm: {
        seo: {
            title: 'Reseller inquiry | Geotab',
            description:
                'Interested in becoming a Geotab Reseller? Please fill in the form below and we will contact you as soon as possible.',
        },
        heroImagePath: contactUs.resellerSupport,
        title: 'Reseller inquiry',
        header: 'Contact Reseller support',
        subtext:
            'Interested in becoming a Geotab Reseller? Please fill in the form below and we will contact you as soon as possible.',
        inquiryType: 'Become a Reseller/Partner',
    },
    generalInquiries: {
        seo: {
            title: 'General inquiries | Geotab',
            description: 'Have a question? Please fill out the form below and we will contact you as soon as possible.',
        },
        heroImagePath: contactUs.generalInquiriesHeroImage,
        title: 'General inquiries',
        header: 'Contact us',
        subtext: 'Have a question? Please fill out the form below and we will contact you as soon as possible.',
        inquiryType: 'Other',
    },
    salesInquiries: {
        seo: {
            title: 'Sales inquiries | Geotab',
            description:
                'For questions or comments regarding purchasing any of our products or solutions, please fill out the form below. We will contact you as soon as possible.',
        },
        heroImagePath: contactUs.salesInquiryHeroImage,
        title: 'Sales inquiry',
        header: 'Contact sales',
        subtext:
            'For questions or comments regarding purchasing any of our products or solutions, please fill out the form below. We will contact you as soon as possible.',
        inquiryType: 'Sales',
    },
    myGeotabLogin: {
        loginText: 'Log in',
        registerText: 'Register',
        newsText: 'Featured news',
        seo: {
            title: 'MyGeotab Login / Register | North America | Geotab',
            description:
                'Log in securely to your MyGeotab dashboard. Don\'t have an account? Register as a new user in North America.Log in securely to your MyGeotab dashboard. Don\'t have an account? Register as a new user in North America.',
        },
        heading: 'North America MyGeotab Login / Register',
    },
    becomeInstaller: {
        seo: {
            title: 'Become An Installer | Fleet Telematics | Geotab',
            description: 'Fill out the form and get license to be an official Geotab hardware installer',
        },
        title: 'Become an installer',
        companyDetails: {
            title: 'Company details',
            description1:
                'Only ONE application is required per company. If your company has already applied, and you want to take the Installer Certification Test, please go to',
            takeTest: 'Take Test',
            description2: 'and enter the Company ID assigned to your company.',
            takeTestLinkPath: 'application-test/',
        },
        stepOneForm: {
            companyName: 'Company name',
            email: 'Email address',
            website: 'Website',
            phoneNumber: 'Phone number',
            streetAddress: 'Street address',
            city: 'City',
            selectCountry: 'Select country',
            stateProvince: 'State/Province',
            postalCode: 'Postal Code',
            next: 'Next',
        },
        stepTwo: {
            title: 'Installer application and agreement - Terms and conditions',
            note: 'Note: You must scroll down to end to be able to Accept',
            iAgree: 'I agree',
            back: 'Back',
            agreement: `1. Appointment as Authorized Installer: Subject to the terms and conditions hereof, upon successful completion of all training, testing and authorization requirements (the “Authorization Requirements”) that we may specify or modify from time to time by at least one member of your staff (each an “Authorized Individual”), we will appoint you, and you agree to act, as an authorized installer of our products (a “Geotab Authorized Installer”) to provide, through Authorized Individuals, installation, configuration, setup and other services (the “Installer Services”) to users or resellers of our products (“Geotab Users”).
                        \nYou are solely responsible for your dealings with Geotab Users, any expenses you incur in connection with such dealings, and any fees or other amounts due or owing to you. This Agreement does not grant you any rights to sell or resell our products or automated services.
                        \nYou must at all time have at least one Authorized Individual on staff to retain your status as a Geotab Authorized Installer.  If at any time you do not have any Authorized Individuals on staff, you must notify us, and we reserve the right to suspend your designation as a Geotab Authorized Installer until such time as you again have at least one Authorized Individual on staff.
                        \nYou are not permitted, without our prior written consent, to make any representations or warranties in respect of our products or services, reverse engineer or otherwise modify our products, or monitor, intercept or interfere with the transmission of data to or from Geotab Users in the course of their use of our products or services. You must only use a Geotab approved harness acquired from a Geotab-authorized channel with Geotab telematics devices. Modification of Geotab-approved harnesses or use of non-approved harnesses is strictly prohibited.  This can cause serious safety risks including fire and lead to serious personal injury, death and/or damage to vehicle or property.
                        \nThis is a non-exclusive Agreement, we reserve the right to appoint other Geotab Authorized Installers in any area at any time at our sole discretion.
                        \n2. Term and Termination: The initial term (the “Initial Term”) of this Agreement commences upon the date we send you written confirmation that your application has been accepted and continues until the day immediately preceding the third anniversary thereof unless otherwise terminated in accordance herewith. Upon the expiration of the Initial Term or any Renewal Term, the Agreement will automatically renew for subsequent terms of one year each (each a “Renewal Term” and together with Initial Term, the “Term”) unless either party gives written notice of non-renewal effective at the end of the then-current Initial Term or Renewal Term not less than 120 days prior to such expiration, or this Agreement is otherwise terminated in accordance herewith.
                        \nEither party may terminate this Agreement at any time for any reason or for no reason by giving 30 days prior written notice to the other party. Any failure by you to meet or continue to meet all applicable Authorization Requirements is deemed to be a material breach of this Agreement. You must return all our information and materials provided to you pursuant to this Agreement upon expiration or termination.
                        \n3. Insurance: During the Term and for a period of at least two years thereafter, you must procure and maintain, in full force and effect and at your expense, a comprehensive general liability insurance policy or policies with a policy limit of not less than USD $1 million per occurrence and $2 million in the aggregate, covering death, personal injury, property damage, contractual liability, operations liability, advertising injury and product liability, and any other liability arising out of or occurring in connection with your business under any agreement between you and any Geotab User. Such policy or policies shall provide that Geotab is an additional named insured and that they will not be cancelled or altered without at least 60 days’ prior written notice to us. You must furnish us with a certificate of such insurance upon our request. The purchase of such insurance coverage shall not relieve you of any of your obligations or liabilities under this Agreement.
                        \n4. Disclaimers and Limitations: WE EXPRESSLY DISCLAIM ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS WHATSOEVER, EXPRESS OR IMPLIED, INCLUDING ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS OF QUALITY, PERFORMANCE, MERCHANTABILITY, MERCHANTABLE QUALITY, DURABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND THOSE ARISING BY STATUTE OR OTHERWISE IN LAW OR FROM A COURSE OF DEALING OR USE OF TRADE. YOU SHALL INDEMNIFY AND HOLD HARMLESS US, OUR AGENTS, SUPPLIERS, DISTRIBUTORS, SUB-DISTRIBUTORS, SUCCESSORS OR ASSIGNS, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, SHAREHOLDERS AND EMPLOYEES (EACH AN “INDEMNIFIED PARTY”), FROM AND AGAINST ANY AND ALL DAMAGES, LIABILITIES, COSTS, LOSSES AND EXPENSES (INCLUDING REASONABLE LEGAL COSTS AND FEES) ARISING FROM OR RELATED TO ANY CLAIM, DEMAND, COMPLAINT OR ACTION BY A THIRD PARTY WHICH AN INDEMNIFIED PARTY MAY SUFFER OR INCUR IN CONNECTION WITH YOUR BUSINESS ACTIVITIES, YOUR NEGLIGENCE OR WILFUL MISCONDUCT. YOU AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL OUR LIABILITY TO YOU EXCEED THE AMOUNT YOU HAVE PAID TO US HEREUNDER IN THE ONE MONTH PERIOD IMMEDIATELY PRECEDING THE TIME THE CAUSE OF ACTION AROSE AND IN NO EVENT WILL ANY INDEMNIFIED PARTY BE LIABLE OR OTHERWISE RESPONSIBLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL LOSSES OR DAMAGES, INCLUDING LOSS OF REVENUE OR PROFITS, LOSS OF DATA, BUSINESS INFORMATION OR LOSS OF USE THEREOF, FAILURE TO REALIZE EXPECTED PROFITS OR SAVINGS, COST OF CAPITAL, LOSS OF BUSINESS OPPORTUNITIES, LOSS OF GOODWILL OR ANY OTHER NON-DIRECT, PECUNIARY, COMMERCIAL OR ECONOMIC LOSS OR DAMAGE OF ANY KIND OR ANY CLAIM BY ANY THIRD PARTY, EVEN IF SUCH INDEMNIFIED PARTY COULD FORESEE OR HAVE BEEN ADVISED OF THE POSSIBILITY OF ANY SUCH LOSS OR DAMAGE.
                        \n5. Intellectual Property: All trade names, company names, trademarks, service marks, domain names and other product and service names and logos on our products, software, or website are owned by Geotab or its licensors and may not be used except as expressly permitted hereunder. Subject to the terms and conditions of this Agreement, we grant you a limited, non-transferable, non-exclusive and royalty-free right to use our trademarks which have been expressly identified by us as those which can be used by Geotab Authorized Installers, to market, promote, advertise and sell Installer Services. You must comply with any guidelines provided by us to you in respect of your usage thereof. We may inspect your compliance regarding such usage from time to time. You understand and agree that any feedback or input you provide to us shall become our exclusive property. You grant us a limited, non-exclusive, and royalty-free right to use your corporate name and logo to identify and promote you as a Geotab Authorized Installer and you agree that we may provide your name and contact information to Geotab Users or prospective Geotab Users who are seeking Installer Services.
                        \n6. Confidentiality: You agree that any and all information, written or oral, which is proprietary or confidential in nature which we disclose to you shall be treated by you as confidential. You will not disclose such information to any third party and will use such information solely for the purposes of performing your obligations hereunder. You must promptly notify us of any misuse, misappropriation, or disclosure of confidential information in contravention of this Agreement.
                        \n7. General:
                        (a) Communications. You agree to receive communications from us, including service notices through e-mail and postings on our website. We may also send notices to you by fax, post, personal delivery or courier sent to the most recent address or number you have provided to us through our website. You may send communications to us through our website, subject to this provision. You agree that legal claims and demands against us must be sent to us by personal delivery, registered post or courier to Geotab Inc., 2440 Winston Park Drive Oakville, ON, Canada L6H 7V2, ATTN: General Counsel or such other address as we may advise you from time to time in accordance with this provision. Any notice will be deemed to be received: (i) if mailed, ten business days following mailing; (ii) if delivered by courier, three business days following acceptance for delivery by the courier; (iii) if delivered personally, on the actual day of delivery unless after business hours, in which case it will be deemed to be received the following business day; (iv) if delivered by e-mail, on the next business day following transmission; and (v) if delivered through our website, on the actual day our website confirms the notice to have been delivered unless after business hours, in which case it will be deemed to be received the following business day.
                        \n(b) Severability. To the extent that any portion of this Agreement is deemed to be invalid, illegal or unenforceable, such portion shall be severed and deleted or limited so as to give effect to the intent of the parties insofar as possible.
                        \n(c) Force Majeure. We will be relieved of our obligations hereunder and will not be liable to you or to any third party if we are unable or fail to perform any of our obligations under this Agreement as a result of any cause beyond our reasonable control, for so long as such cause prevents us from so performing.
                        \n(d) Governing Law. This Agreement shall be governed by and construed in accordance with the laws of the Province of Ontario. This Agreement shall be deemed to be made in the Province of Ontario and you hereby submit to the non-exclusive jurisdiction of the courts of the Province of Ontario.
                        \n(e) Waivers. No waiver by either party of any breach by the other of any of its covenants, obligations and agreements hereunder shall be a waiver of any subsequent breach of any other covenant, obligation or agreement, nor shall any forbearance to seek a remedy for any breach be a waiver of any rights and remedies with respect to such or any subsequent breach.
                        \n(f) Independent Contractors. Nothing contained herein will be deemed or construed by you or us, or by any third party, to create the relationship of partnership or joint venture or a relationship of principal and agent, employer-employee, primary-subordinate, or franchisor-franchisee between you and us. Except as expressly set out in this Agreement, nothing whatsoever in this Agreement shall constitute either party as having the authority to bind the other in any manner whatsoever.
                        \n(g) Assignment and Subcontracting. You may subcontract or delegate your obligations hereunder, provided that if you do so, you may only subcontract or delegate to another Geotab Authorized Installer, and you will be responsible and liable for all acts or omissions of those to whom you subcontract and delegate as if such acts or omissions were yours, including any breach of this Agreement. You must ensure that such subcontractors or delegates comply with the applicable provisions of this Agreement, including, without limitation, procuring insurance coverage. We may assign this Agreement or any rights or obligations hereunder in whole or in part. You may not assign or transfer this Agreement or any of your rights or obligations hereunder, including by operation of law.
                        \n(h) Entire Agreement. The Agreement contains the entire agreement between you and us relating to your appointment as a Geotab Authorized Installer, and supersedes all prior agreements between you and us relating to same. There are no representations, warranties, terms, conditions, undertakings or collateral agreements, express, implied or statutory, between you and us other than as expressly set forth in this Agreement.`,
        },
        stepThree: {
            review: 'Review your information',
            companyDetails: 'Company details',
            confirm: 'Confirm',
            cancel: 'Cancel',
            editDetails: 'Edit details',
            takeTest: 'Take test',
            thankYou: 'Thank you! Your application has been submitted.',
            uniqueKey: 'Your company has been assigned ID',
            reviewText:
                'You must review the information displayed above and ensure that it is true, accurate, complete and correct. If any changes are required, please make such changes before proceeding. You acknowledge and understand that that we reserve the right to reject your application or, following acceptance, terminate the Agreement and/or suspend your status as a Geotab Certified Installer, if we determine that any information or documentation you have provided in connection with your application is not true, accurate, complete or current. By clicking “Confirm” below, you: (a) will be submitting your application and the above information to Geotab; (b) represent, warrant and covenant that you have authority to bind, the entity identified above, that the information you have provided above is true, accurate, current and complete and that you, as a representative of the entity identified above agree to be bound by the terms and conditions set forth above. If you do not agree with any of the terms and conditions or do not wish to proceed with the application for any reason, do not click “Confirm” below. Instead, click “Cancel” below to cancel your application.',
            note: 'To complete the application process, please submit your proof of insurance and company logo to installcert@geotab.com. At least one individual from your company must complete and pass the Installer Certification Test.',
        },
        stepLabels: {
            'Company details': 'Company details',
            'Terms and agreement': 'Terms and agreement',
            Confirmation: 'Confirmation',
        },
    },
    platformPartnersForm: {
        howToGetStarted: 'How to get started',
        formField: {
            firstName: 'First name',
            lastName: 'Last name',
            company: 'Company',
            email: 'Email',
            phone: 'Phone',
            areaOfInterest: {
                label: 'Area of interest',
                options: ['Bring Your Own UI', 'Bring Your Own Device', 'IoT Connectivity'],
            },
            commentsText: 'Anything else you\'d like us to know?',
            submitButton: 'Submit',
        },
    },
    requestDemo: {
        optimizeYourFleetToday: 'Optimize your fleet today',
        formField: {
            leadSource: 'Geotab Demo Form',
            firstName: 'First name',
            lastName: 'Last name',
            company: 'Company',
            email: 'Email',
            selectFleetSize: 'Select fleet size',
            phone: 'Phone',
            selectCountry: 'Select Country',
            city: 'City',
            industry: 'Select Industry',
            selfAttribution: 'How did you hear about us?',
            consentText:
                'Yes, I would like to receive marketing communications regarding Geotab.com products, services, newsletters, and events. Your information will be used in accordance with Geotab’s <a href="https://docs.google.com/document/d/e/2PACX-1vR5H-O69C-Uf4CDNQP2v1meGzV-6AmKnnbxz4PLEMqBTlvW82Eeklo12cvxmYy-6eUn2pdQJP-ZdHUk/pub" rel="noopener" target="_blank">privacy policy</a>.',
            requestAFreeDemo: 'Request a free demo',
            formTitle: 'Sign up for a FREE demo',
            businessEmail: 'Business Email',
            areaOfInterest: 'Area of Interest',
        },
    },
    siteWideDemoForm: {
        formField: {
            firstName: 'First name',
            lastName: 'Last name',
            company: 'Company name',
            title: 'Job title',
            email: 'Email',
            industry: 'Industry',
            fleetSize: 'Fleet size',
            phone: 'Phone',
            country: 'Country',
            state: 'State/Province',
            city: 'City',
            selfAttribution: 'How did you hear about us?',
            multipleRegionText: 'Does your company operate in multiple regions? (Optional)',
            consentText:
                'Receive marketing communications regarding products, services, and events. <a href="https://docs.google.com/document/d/1sVygLN02w2xNovFY4q5vw-oAzfYxCd7WLhyToElgDbs/pub">Privacy Policy</a>',
        },
        helperText: {
            forceMultiPick: 'Select at least two options',
        },
        returnToPrevious: 'Back',
        a11yInstructions:
            'This form is made up of multiple pages, to continue to the next page please fill out the following fields or checkboxes.',
    },
    blogPost: {
        publishedOn: 'Published on',
        updatedOn: 'Last updated on',
        categoryIn: 'in',
        category: 'category',
        publishedBy: 'by',
        relatedPosts: 'Related posts',
        likeButtonText: 'If you liked this post, let us know!',
        likeButtonAriaText: 'Like post',
        disclaimerTitle: 'Disclaimer',
        disclaimerSummary:
            'Geotab\u0027s blog posts are intended to provide information and encourage discussion on topics of interest to the telematics community at large.  Geotab is not providing technical, professional or legal advice through these blog posts.  While every effort has been made to ensure the information in this blog post is timely and accurate, errors and omissions may occur, and the information presented here may become out-of-date with the passage of time.',
        share: 'Share',
        shareArticle: 'Share article',
        copyUrl: 'Copy URL',
        copiedUrlMessage: 'Copied',
        shareWith: 'Share with',
        keyInsights: 'Key insights',
    },
    video: {
        title: 'Additional resources',
        communityCard: {
            image: {
                imagePath: videos.forumsGraphic,
                imageAlt: 'An illustration of two individuals interacting with technology and messages.',
            },
            title: 'Community',
            body: 'Ask questions, share experiences, learn from peers and help shape the future of telematics.',
            linkPath: 'https://community.geotab.com/s/',
            linkText: 'Visit Community',
            linkTarget: true,
        },
        blogCard: {
            image: {
                imagePath: videos.blogGraphic,
                imageAlt: 'An illustration of two individuals reading an oversized book with a coloured bookmark.',
            },
            title: 'Blog',
            body: 'Your online source of fleet management and mobility news, insights and best practices.',
            linkPath: '/blog/',
            linkText: 'Visit blog',
            linkTarget: true,
        },
        whitePaperCard: {
            image: {
                imagePath: videos.whitePapersGraphic,
                imageAlt: 'An illustration of two individuals interacting with some documents and a magnifying glass.',
            },
            title: 'White papers',
            body: ' Fleet management resources to help you optimize and grow your business.',
            linkPath: '/white-papers/',
            linkText: 'Read white papers',
            linkTarget: true,
        },
    },
    authorBiography: {
        authorOverview: {
            leanMoreAbout: 'Learn more about {authorName}',
        },
        geotabSubscribe: {
            subscribeTo: 'Subscribe to get industry tips and insights',
            description:
                'Sign up for Geotab\'s monthly newsletter to stay updated on news and tips from the world of telematics. You can unsubscribe at any time.',
        },
        authorPosts: {
            postsBy: 'Posts by',
            postBy: 'Post by',
            noPostAvailable: 'No posts available',
        },
        subscribeForm: {
            subscribe: 'Subscribe',
            email: 'E-mail address *',
        },
        subscribeFormMC: {
            subscribe: 'Subscribe',
            firstName: 'First name',
            lastName: 'Last name',
            email: 'Email address ',
            formId: 'blog_subsciption_form',
            consentTextSentences:
                'Yes, I would like to receive marketing communications regarding Geotab products, services, newsletters, and events. Your information will be used in accordance with ',
            consentLinkText: 'Geotab’s privacy policy.',
            consentLink: 'https://www.geotab.com/privacy-policy/',
        },
        errors: {
            emailOnList: 'The email was already in our list, please check your subscription status here:',
            unsubscribed: 'The email was previously unsubscribed, please contact our admin to be added again.',
            error: 'An error has occurred, please try again later or contact our admin.',
        },
        publications: 'Publications',
        readPublications: 'Read publications',
    },
    contactGeneralInquiry: {
        formField: {
            fullName: 'Full name',
            company: 'Company',
            email: 'Email',
            selectFleetSize: 'Select fleet size',
            phone: 'Phone',
            selectCountry: 'Select Country',
            city: 'City',
            comments: 'Questions/Comments:',
            submit: 'Submit',
        },
    },
    findInstaller: {
        title: 'Find an Authorized Geotab Installer near you',
        searchPlaceholder: 'Search by Name, City, State/Province, Country, Skill level',
    },
    findCarModels: {
        title: 'Find a model',
        searchPlaceholder: 'Search by Make, Model or Year',
    },
    subscribePageBanner: {
        banner: {
            title: 'Your go-to source for fleet management insights',
            body: 'Sign up for Geotab’s monthly newsletter',
            bannerContentHeading: 'Here’s what we pack into every month’s issue:',
            bannerContentList: [
                'Check out the latest industry news, customer success stories, product updates, and helpful technology tips you need to move your fleet forward',
                'Gain exclusive access to our powerhouse team of sales and product experts to get the answers you need to fine-tune your fleet experience',
            ],
            bannerContentFooter: 'Don’t miss your chance to shift your fleet into high gear! Sign up today!',
            consentTextSentences:
                'Yes, I would like to receive marketing communications regarding Geotab products, services, newsletters, and events. Your information will be used in accordance with ',
            consentLinkText: 'Geotab’s privacy policy.',
            consentLink: 'https://www.geotab.com/privacy-policy/',
            showForm: true,
            marketingCloudFormId: 'blog_subsciption_form',
            formField: {
                firstName: 'First name',
                lastName: 'Last name',
                email: 'Email',
            },
            button: {
                buttonText: 'Subscribe',
                buttonType: 'contained',
            },
            formInfo1: 'You can unsubscribe at any time. Please refer to our',
            privacyPolicyText: 'Privacy Policy',
            privacyPolicyLink: 'https://www.geotab.com/privacy-policy/',
            formInfo2: 'or',
            contactUsText: 'Contact Us',
            contactUsLink: 'https://www.geotab.com/contact-us/',
            formInfo3: 'for details.',
            formSuccess: {
                strong: 'Thank you! Your email has been received.',
                normal: '\u00A0For more trending updates follow us on\u00A0',
                and: 'and',
            },
        },
        image: {
            imagePath: subscribePage.subscribePageBannerImage,
            imageAlt: 'Phone display showing news and updates on geotab.com',
        },
    },
    transportationContactForm: {
        title: 'Download the whitepaper',
        subTitle: 'Gain useful insights into Flexible, cost competitive strategies for European Heavy Goods fleets',
        formField: {
            firstName: 'First name',
            lastName: 'Last name',
            company: 'Company name',
            city: 'City',
            email: 'Email',
            phone: 'Phone',
            selectCountry: 'Country',
            fleetSize: 'Fleet Size',
            consentTextSentences: [
                'Yes, I would like to receive marketing communications regarding Geotab products, services, newsletters, and events. ',
                'Your information will be used in accordance with ',
            ],
            consentLinkText: 'Geotab’s privacy policy.',
            consentLink: 'https://docs.google.com/document/d/1sVygLN02w2xNovFY4q5vw-oAzfYxCd7WLhyToElgDbs/pub',
            submit: 'Submit',
        },
    },
    eldSectionForm: {
        title: 'Let us show you how Geotab can do more for you.',
        formField: {
            firstName: 'First name',
            lastName: 'Last name',
            company: 'Company name',
            city: 'City name',
            email: 'Email',
            phone: 'Phone',
            fleetSize: 'Fleet Size',
            selectCountry: 'Country',
            selectIndustry: 'Industry',
            areaOfInterest: 'Area of interest',
            consentTextSentences: [
                'Yes, I would like to receive marketing communications regarding Geotab products, services, newsletters, and events. ',
                'Your information will be used in accordance with ',
            ],
            consentLinkText: 'Geotab’s privacy policy.',
            consentLink: 'https://docs.google.com/document/d/1sVygLN02w2xNovFY4q5vw-oAzfYxCd7WLhyToElgDbs/pub',
            submit: 'Submit',
        },
    },
    readMore: 'Read more',
    noStoriesFound: 'No Stories found',
    viewArticle: 'View article',
    micrositeForm: {
        tooltip: {
            pleaseFillOutThisField: 'Please fill out this field',
            pleaseSelectAnItem: 'Please select an item in the list',
        },
        title: 'Optimize your fleet today',
        subTitle: 'Let us show you how simple it is to use our telematics platform and devices to manage your fleet',
        countryName: '',
        firstName: 'First name',
        lastName: 'Last name',
        company: 'Company',
        email: 'Email',
        fleetSize: 'Fleet size',
        phone: 'Phone',
        country: 'Country',
        city: 'City',
        state: 'State',
        province: 'Province',
        submitButton: 'Submit',
    },
    securityForm: {
        formField: {
            fullName: 'Full name',
            email: 'Email',
            message: 'Message',
        },
        submit: 'Submit',
        thankYou: 'Thank you, our security team will contact you as soon as possible.\u00A0',
    },
    articleCard: {
        viewLink: 'Read white paper',
    },
    salesforce: {
        formLanguage: 'English Global',
        demoFormDefaultCampaignId: '7012J000001Yax8QAC',
    },
    keylessRental: {
        firstName: 'First name',
        firstNamePlaceholder: 'First name',
        lastName: 'Last name',
        lastNamePlaceholder: 'Last name',
        companyName: 'Company name',
        companyNamePlaceholder: 'Company name',
        email: 'Email address',
        emailPlaceholder: 'Email address',
        country: 'Select country',
        countryPlaceholder: 'Select country',
        submitButton: 'Free consultation',
    },
    LCVForm: {
        firstName: 'First name',
        lastName: 'Last name',
        companyName: 'Company name',
        email: 'Email address',
        country: 'Select country',
        phone: 'Phone',
        submitButton: 'Free consultation',
    },
    threeGContactForm: {
        tooltip: {
            pleaseFillOutThisField: 'Please fill out this field',
            pleaseSelectAnItem: 'Please select an item in the list',
        },
        title: 'Grow your fleet with ProPlus',
        subTitle:
            'For more information about ProPlus or the 3G network sunset, please fill out the form below and we\'ll be in touch as soon as possible.',
        countryName: '',
        firstName: 'First name',
        lastName: 'Last name',
        company: 'Company',
        email: 'Email',
        fleetSize: 'Fleet size',
        phone: 'Phone',
        country: 'Country',
        city: 'City',
        identify: 'Please help us identify who you are:',
        telematicsDevice: 'What is your current telematics device?',
        state: 'State/Province',
        submitButton: 'Submit',
    },
    imagePopout: {
        readPrevious: 'Read Previous',
        readNext: 'Read Next',
    },
    evLeadForm: {
        title: 'Optimize your electric fleet',
        subTitle:
            'To request an EV reporting demo, or to receive more information regarding EV performance, please fill out the form below.We will contact you as soon as possible.',
        formField: {
            firstName: 'First name',
            lastName: 'Last name',
            company: 'Company',
            city: 'City',
            email: 'Email',
            phone: 'Phone',
            selectIndustry: 'Industry',
            areaOfInterest: 'Area of interest',
            selectCountry: 'Country',
            fleetSize: 'Fleet size',
            userType: {
                resellerType: 'Geotab reseller',
                newCustomerType: 'New Geotab customer',
                existCustomerType: 'Existing Geotab customer',
            },
            consentTextSentences: [
                'Yes, I would like to receive marketing communications regarding Geotab products, services, newsletters, and events. Your information will be used in accordance with ',
            ],
            consentLinkText: 'Geotab’s privacy policy.',
            consentLink: 'https://www.geotab.com/privacy-policy/',
            submit: 'Submit',
        },
    },
    oemForm: {
        title: 'Interested in a pilot?',
        formField: {
            firstName: 'First name',
            lastName: 'Last name',
            company: 'Company',
            city: 'City',
            email: 'Email',
            phone: 'Phone',
            selectCountry: 'Country',
            fleetSize: 'Fleet size',
            consentTextSentences: [
                'Yes, I would like to receive marketing communications regarding Geotab products, services, newsletters, and events. Your information will be used in accordance with ',
            ],
            consentLinkText: 'Geotab’s privacy policy.',
            consentLink: 'https://www.geotab.com/privacy-policy/',
            submit: 'Submit',
        },
    },
    oemFormEU: {
        howToGetStarted: 'See how you can integrate with Geotab today',
        formField: {
            firstName: 'First name',
            lastName: 'Last name',
            company: 'Company',
            email: 'Email',
            phone: 'Phone',
            commentsText: 'Anything else you’d like us to know?',
            submitButton: 'Submit',
        },
    },
    energyFormMC: {
        optimizeYourFleetToday: 'See how smart charging works',
        firstName: 'First name',
        lastName: 'Last name',
        company: 'Company',
        email: 'Email',
        jobTitle: 'Job title',
        message: 'Message',
        consentText:
            'Yes, I would like to receive marketing communications regarding Geotab.com products, services, newsletters, and events. Your information will be used in accordance with Geotab’s privacy policy.',
        requestAFreeDemo: 'Request a free demo',
    },
    gatedWhitePaper: {
        firstName: 'First name',
        lastName: 'Last name',
        company: 'Company',
        email: 'Business email',
        jobTitle: 'Job Title',
        consentText:
            'Yes, I would like to receive marketing communications regarding Geotab.com products, services, and events. Your information will be used in accordance with Geotab\'s privacy policy.',
        submitButton: 'Download',
    },
    subscribeEnergyFormMC: {
        subscribeTitle: 'Get the latest Geotab Energy & EV charge management news delivered right to your inbox.',
        subscribeBody:
            'Enter your email below to start receiving the Geotab Energy monthly newsletter containing load management news and resources.',
        subscribe: 'Subscribe',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email address ',
        consentText1: 'You can unsubscribe at any time. Please refer to our ',
        consentText2: ' or ',
        consentText3: ' for details.',
        consentLink1: '/privacy-policy/',
        consentLinkText1: 'Privacy Policy',
        consentLink2: '/contact-us/',
        consentLinkText2: 'Contact Us',
        bannerImage: subscribePage.phoneSoftwareEnergy,
        bannerImageAlt: 'Phone display showing news and updates on geotab.com',
    },
    truckingDemoForm: {
        title: 'Optimize your fleet today!',
        subTitle: 'Contact our experts to learn how Geotab can help you insure your fleet.',
        formField: {
            firstName: 'First Name',
            lastName: 'Last Name',
            company: 'Company',
            load: 'Cargo',
            selectFleetSize: 'Fleet Size',
            email: 'Email',
            phone: 'Phone',
            selectCountry: 'Country',
            city: 'City',
            consentTextSentences: [
                'Yes, I would like to receive marketing communications regarding Geotab.com products, services, newsletters, and events. Your information will be used in accordance with ',
            ],
            consentLinkText: 'Geotab Privacy Policy.',
            consentLink: 'https://www.geotab.com/privacy-policy/',
            submit: 'Submit',
        },
    },
    formPlaceholders: {
        firstNamePlaceholder: 'Enter a first name\u2026',
        lastNamePlaceholder: 'Enter a last name\u2026',
        phonePlaceholder: 'Enter a phone number\u2026',
        emailPlaceholder: 'Enter an email\u2026',
        companyPlaceholder: 'Enter a company\u2026',
        yourCompanyPlaceholder: 'Enter your company\'s name\u2026',
        cityPlaceholder: 'Enter a city\u2026',
        commentsPlaceholder: 'Enter any questions/comments\u2026',
        fleetPlaceholder: 'Select a fleet size\u2026',
        selfAttributionPlaceholder: 'Enter how you heard about Geotab\u2026',
        approxFleetPlaceholder: 'Select an approximate fleet size\u2026',
        industryPlaceholder: 'Select an industry category\u2026',
        subIndustryPlaceholder: 'Select a subindustry category\u2026',
        countryPlaceholder: 'Select a country\u2026',
        fullNamePlaceholder: 'Enter a full name\u2026',
        messagePlaceholder: 'Enter a message\u2026',
        identityPlaceholder: 'Select a category\u2026',
        surnamePlaceholder: 'Enter a surname\u2026',
        cargoPlaceholder: 'Enter your cargo type\u2026',
        resellerPlaceholder: 'Enter a reseller\'s name\u2026',
        interestPlaceholder: 'Select an area of interest\u2026',
        companyUrlPlaceholder: 'Enter your company website (https://example.com)\u2026',
        contactCommentPlaceholder:
            'Tell us about your business and why you are interested in becoming a Geotab partner.\u2026',
        clientPlaceholder: 'Please help us identify you\u2026',
        statePlaceholder: 'Select a state\u2026',
        provincePlaceholder: 'Select a province\u2026',
        titlePlaceholder: 'Select a title\u2026',
        userTitlePlaceholder: 'Enter a title\u2026',
        regionsOperatePlaceholder: 'Select at least two options\u2026',
    },
    formTranslations: {
        firstName: 'First Name',
        lastName: 'Last Name',
        company: 'Company',
        email: 'Email',
        phone: 'Phone',
        city: 'City',
        country: 'Country',
        interest: 'Area of interest',
        industry: 'Industry',
        subIndustry: 'Sub-Industry',
        client: 'Client type',
        fleetSize: 'Fleet size',
        selfAttribution: 'Self-Attribution',
        submit: 'Submit',
        yes: 'Yes',
        no: 'No',
        yesToProceed: 'Please select Yes from above to proceed',
        checkedToProceed: 'Please tick the checkbox above to proceed',
        state: 'State',
        province: 'Province',
        title: 'Title',
        userTitle: 'Your title',
        forceMultiPick: 'Select at least two options',
    },
    republishBlog: {
        title: 'Republish this article for free',
        republishButton: 'Republish',
        modalTitle: 'Republish this article',
        tagLine:
            'Geotab blog postings available on geotab.com/blog/ are published by Geotab under a Creative Commons - Attribution-NoDerivatives 4.0 license. This means you can republish our blog postings online or in print at no charge, subject to the following guidelines:',
        contentList: [
            'you can\'t edit or modify our material',
            'you must credit Geotab as the original creator of the material',
            'you must provide a link back to the original blog posting on geotab.com',
            'you must provide a link to the license',
            '<strong>NOTE:</strong> Images appearing in blog posts are not included in these republishing rights and are automatically removed for purposes of republication',
        ],
        contentBodyBold: 'For example',
        contentBody:
            'This article originates from Geotab Inc. and is republished from geotab.com/blog/ with permission under the Creative Commons - Attribution-NoDerivatives 4.0 license.',
        contentSubtitle: 'Questions? Our Content Team can help.',
        emailLabel: 'Email',
        emailLink: 'mailto:content@geotab.com',
        emailText: 'content@geotab.com',
        copyText: 'Copied!',
        copyButtonBefore: 'Copy to clipboard',
        copyButtonAfter: 'Copied article to clipboard',
        republishFooterText1:
            'This article is republished from <a href="https://geotab.com">Geotab</a> under a Creative Commons license.',
        republishFooterText2: 'Read the',
        republishFooterText3: 'original article',
    },
    oemCampaignForm: {
        title: 'Start the switch to 4G today.',
        subTitle: 'Our integration experts will make it easy. Fill out the form below and we’ll reach out.',
        formField: {
            firstName: 'First name',
            lastName: 'Last name',
            email: 'Email',
            phone: 'Phone',
            company: 'Company name',
            reseller: 'Reseller name',
            consentTextSentences: [
                'Yes, I would like to receive marketing communications regarding Geotab products, services, newsletters, and events. ',
                'Your information will be used in accordance with ',
            ],
            consentLinkText: 'Geotab’s privacy policy.',
            consentLink: 'https://docs.google.com/document/d/1sVygLN02w2xNovFY4q5vw-oAzfYxCd7WLhyToElgDbs/pub',
            submit: 'Make the switch today!',
        },
    },
    search: {
        seo: {
            title: 'Search results for',
        },
        search: 'Search',
        searchBarPlaceholder: 'Search for topics, articles, documents, and more.',
        searchResults: 'search results',
        showing: 'Showing',
        of: 'of',
        results: 'results',
        noResults: {
            sorryMessage: 'Sorry, we can\'t find results for',
            invalidSearch: 'Sorry, your search input is invalid.',
            searchTips: 'Search tips',
            spellingCheck: 'Check your spelling and try again.',
            simplerTerms: 'Search for simpler, shorter terms.',
            lessSpecific: 'Search for something less specific.',
            differentWords: 'Try different key words or try selecting a different date range.',
        },
        filter: {
            contentType: 'Content type',
            filterButtonText: 'Filter',
            applyFilters: 'Apply filters',
            clearFilters: 'Clear filters',
        },
        dateSelection: {
            dateUpdated: 'Date updated',
            all: 'All',
            30: 'Last 30 days',
            90: 'Last 90 days',
            365: 'Last year',
        },
        popularTopics: {
            title: 'Popular Topics',
            links: [
                {
                    title: 'Surfsight',
                    href: 'https://marketplace.geotab.com/partner/surfsight-a-lytx-solution/',
                    target: '_blank',
                },
                {
                    title: 'Marketplace',
                    href: 'https://marketplace.geotab.com/',
                    target: '_blank',
                },
                {
                    title: 'Electric vehicles',
                    href: 'https://www.geotab.com/fleet-management-solutions/electric-vehicles/',
                },
                {
                    title: 'Fleet maintenance',
                    href: 'https://www.geotab.com/fleet-management-solutions/fleet-maintenance/',
                },
                {
                    title: 'Drivewyze',
                    href: 'https://marketplace.geotab.com/partner/drivewyze/',
                    target: '_blank',
                },
                {
                    title: 'Sensata',
                    href: 'https://marketplace.geotab.com/partner/sensatainsights/',
                    target: '_blank',
                },
                {
                    title: 'ELD',
                    href: 'https://www.geotab.com/fleet-management-solutions/eld/',
                },
                {
                    title: 'Fleet safety',
                    href: 'https://www.geotab.com/fleet-management-solutions/fleet-safety/',
                },
            ],
        },
    },
    entityHomeNames: {
        blogArticle: 'Blog',
        pressReleaseArticle: 'Press room',
        whitePaperArticle: 'White papers',
        successStoryArticle: 'Success stories',
        glossaryArticle: 'Glossary',
    },
    entityNames: {
        blogArticle: 'Blog post',
        pressReleaseArticle: 'Press release',
        whitePaperArticle: 'White paper',
        successStoryArticle: 'Case study',
        page: 'Page',
        author: 'Author',
        category: 'Category',
        tag: 'Tag',
        video: 'Video',
        document: 'Document',
        glossaryArticle: 'Glossary',
    },
    knownIssues: {
        seo: {
            title: 'Current Known Issues | Geotab',
            description: 'Current known issues reported for Geotab.',
        },
        header: 'Current Known Issues',
        filterLabels: {
            filterByProduct: 'Filter by Product',
            filterByStatus: 'Filter by Status',
        },
        noIssuesFound: 'No Issues found! Try adjusting your filters.',
    },
    blogTableContent: {
        title: 'Table of Contents',
    },
    articleFAQ: {
        title: 'Frequently Asked Questions',
    },
    blogInfo: {
        readMinutes: '5 minute read',
        by: 'By',
    },
    noBlogsFound: {
        forAuthor: 'No blogs found for',
        forCategory: 'No blogs found for',
        forTag: 'No results could be found using',
        noArticlesFound: 'No results could be found! Try adjusting your filters.',
    },
    eVCarModelSearch: {
        title: 'Find an electric vehicle model',
        discoverText: 'Discover our extensive support of over 300 makes and models.',
        geotabGlobalLeaderText:
            'Geotab is the global leader in EV telematics, providing critical data signals that enable organizations to effectively strategize, manage, optimize and scale their EV fleet operations.',
    },
    videoTile: {
        watchVideo: 'Watch video',
        relatedVideos: 'Related videos',
        otherVideos: 'Other videos',
        description: 'Description',
    },
    aboutAuthor: {
        about: 'About',
        currentRole: 'Current role',
        experties: 'Expertise',
        accomplishments: 'Accomplishments',
        education: 'Education',
        publications: 'Publications',
    },
    mobileStickyBanner: {
        text: 'Schedule a demo',
        link: '/request-demo/',
    },
    breadcrumb: {
        home: 'Home',
    },
    contactSection: {
        title: 'CONTACT US',
        subHeading: 'How can we direct your inquiry?',
        salesInquiryText: 'Sales inquiry',
        salesInquiryImageAlt: 'Price tag icon',
        salesInquiryLink: '/contact-us/sales-inquiry/',
        customerSupportText: 'Customer support',
        customerSupportImageAlt: 'Customer chat icon',
        customerSupportLink: 'https://community.geotab.com/',
        contactLinks: [
            {
                title: 'Become a marketplace partner',
                href: 'https://marketplace.geotab.com/integrate-geotab/',
            },
            {
                title: 'Become a reseller',
                href: 'https://www.geotab.com/contact-us/become-a-reseller',
            },
            {
                title: 'California CCPA inquiries',
                href: 'https://docs.google.com/forms/d/e/1FAIpQLSd5yI1ptFrQR8HfwNseAzfT2qlX6fogjK8vizfn9z3zshFPNA/viewform',
            },
        ],
    },
    glossary: {
        articleCard: {
            publishedOn: 'Published on',
            updatedOn: 'Last updated on',
            readMinutes: '5 minute read',
            share: 'Share',
            otherStories: 'Other Stories',
            keyTakeaways: 'Key Insights',
        },
    },
};
